
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as redirect_45assistantWvpgbnWwjJMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue?macro=true";
import { default as _91txId_930pFQqPofMMMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending/[txId].vue?macro=true";
import { default as affiliatedgXijxQmabMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/affiliate.vue?macro=true";
import { default as ansvarligt_45spil66nGTbyh2gMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue?macro=true";
import { default as redirect_45assistantOsEBFqoFhYMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue?macro=true";
import { default as bankoIAKs1mxOWdMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/banko.vue?macro=true";
import { default as betingelseracxERt23fEMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/betingelser.vue?macro=true";
import { default as bingoJLJy81xxDJMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/bingo.vue?macro=true";
import { default as BlogQnFkHT3HQuMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/Blog.vue?macro=true";
import { default as bonuskode71DN7Q2FiUMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/bonuskode.vue?macro=true";
import { default as casinokxrGO8WAQ4Meta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/casino.vue?macro=true";
import { default as cookiepolitikq5kPZ47HQwMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/cookiepolitik.vue?macro=true";
import { default as faqFXujOQLlR0Meta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/faq.vue?macro=true";
import { default as _91slug_93AFqvJr9y7WMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue?macro=true";
import { default as indexDdPis6dMs7Meta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/index.vue?macro=true";
import { default as index9TKQr2CrjcMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konkurrence/index.vue?macro=true";
import { default as ansvarligt_45spilFbW4SP2l0jMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/ansvarligt-spil.vue?macro=true";
import { default as beskederk15R80BN5fMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/beskeder.vue?macro=true";
import { default as biometri2FDiS7g0QCMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/biometri.vue?macro=true";
import { default as blokeringgt0q9Kmo9rMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/blokering.vue?macro=true";
import { default as _91reportId_93B00yFz0QFoMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/brugerrapport/[reportId].vue?macro=true";
import { default as eid6wKLQl6BVtMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/eid.vue?macro=true";
import { default as indbetalingsloftDLtDIHX1dJMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/indbetalingsloft.vue?macro=true";
import { default as nedkoelingCbjcWYjNRZMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/nedkoeling.vue?macro=true";
import { default as ret_45profiluNOf401djCMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/ret-profil.vue?macro=true";
import { default as sletning8FdEKFD6lHMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/sletning.vue?macro=true";
import { default as spaeringBH96dTlc3JMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/spaering.vue?macro=true";
import { default as kundeserviceHXTIH5gzZeMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/kundeservice.vue?macro=true";
import { default as mobil_45app8b8RA76rmpMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/mobil-app.vue?macro=true";
import { default as om_45osCPpmh4sBlNMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/om-os.vue?macro=true";
import { default as loginfXFJT5toT3Meta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/playgame/login.vue?macro=true";
import { default as presseqoiIaSA5W3Meta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/presse.vue?macro=true";
import { default as privatlivspolitikURQXJT0F8oMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/privatlivspolitik.vue?macro=true";
import { default as signupaDtssRkNU3Meta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/signup.vue?macro=true";
import { default as gemte_45spilAw7JjKiuhhMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue?macro=true";
import { default as indexrBAPQz3AltMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/spilleautomater/index.vue?macro=true";
import { default as spiloversigtJIDg5xn1onMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/spiloversigt.vue?macro=true";
import { default as _91seoName_93wd83QFcm9gMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/spilside/[seoName].vue?macro=true";
import { default as tvMWrdsoTNyeMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/tv.vue?macro=true";
import { default as vinderej5i5HOV9ZAMeta } from "/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/vindere.vue?macro=true";
export default [
  {
    name: "redirect-assistant",
    path: "/payments/redirect-assistant",
    meta: redirect_45assistantWvpgbnWwjJMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue")
  },
  {
    name: "payments-pending",
    path: "/payments/pending/:txId",
    meta: _91txId_930pFQqPofMMMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending/[txId].vue")
  },
  {
    name: "affiliate",
    path: "/affiliate",
    meta: affiliatedgXijxQmabMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/affiliate.vue")
  },
  {
    name: "ansvarligt-spil",
    path: "/ansvarligt-spil",
    meta: ansvarligt_45spil66nGTbyh2gMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue")
  },
  {
    name: "app-redirect-assistant",
    path: "/app/redirect-assistant",
    meta: redirect_45assistantOsEBFqoFhYMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue")
  },
  {
    name: "banko",
    path: "/banko",
    meta: bankoIAKs1mxOWdMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/banko.vue")
  },
  {
    name: "betingelser",
    path: "/betingelser",
    meta: betingelseracxERt23fEMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/betingelser.vue")
  },
  {
    name: "bingo",
    path: "/bingo",
    meta: bingoJLJy81xxDJMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/bingo.vue")
  },
  {
    name: "Blog",
    path: "/Blog",
    meta: BlogQnFkHT3HQuMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/Blog.vue")
  },
  {
    name: "bonuskode",
    path: "/bonuskode",
    meta: bonuskode71DN7Q2FiUMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/bonuskode.vue")
  },
  {
    name: "casino",
    path: "/casino",
    meta: casinokxrGO8WAQ4Meta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/casino.vue")
  },
  {
    name: "cookiepolitik",
    path: "/cookiepolitik",
    meta: cookiepolitikq5kPZ47HQwMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/cookiepolitik.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqFXujOQLlR0Meta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/faq.vue")
  },
  {
    name: "games-entrance-slug",
    path: "/games/entrance/:slug()",
    meta: _91slug_93AFqvJr9y7WMeta || {},
    alias: ["/spil/:slug","/spilleautomater/entrance/:slug"],
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexDdPis6dMs7Meta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/index.vue")
  },
  {
    name: "konkurrence",
    path: "/konkurrence",
    meta: index9TKQr2CrjcMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konkurrence/index.vue")
  },
  {
    name: "konto-ansvarligt-spil",
    path: "/konto/ansvarligt-spil",
    meta: ansvarligt_45spilFbW4SP2l0jMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/ansvarligt-spil.vue")
  },
  {
    name: "konto-beskeder",
    path: "/konto/beskeder",
    meta: beskederk15R80BN5fMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/beskeder.vue")
  },
  {
    name: "konto-biometri",
    path: "/konto/biometri",
    meta: biometri2FDiS7g0QCMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/biometri.vue")
  },
  {
    name: "konto-blokering",
    path: "/konto/blokering",
    meta: blokeringgt0q9Kmo9rMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/blokering.vue")
  },
  {
    name: "konto-brugerrapport-reportId",
    path: "/konto/brugerrapport/:reportId()",
    meta: _91reportId_93B00yFz0QFoMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/brugerrapport/[reportId].vue")
  },
  {
    name: "konto-eid",
    path: "/konto/eid",
    meta: eid6wKLQl6BVtMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/eid.vue")
  },
  {
    name: "konto-indbetalingsloft",
    path: "/konto/indbetalingsloft",
    meta: indbetalingsloftDLtDIHX1dJMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/indbetalingsloft.vue")
  },
  {
    name: "konto-nedkoeling",
    path: "/konto/nedkoeling",
    meta: nedkoelingCbjcWYjNRZMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/nedkoeling.vue")
  },
  {
    name: "konto-ret-profil",
    path: "/konto/ret-profil",
    meta: ret_45profiluNOf401djCMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/ret-profil.vue")
  },
  {
    name: "konto-sletning",
    path: "/konto/sletning",
    meta: sletning8FdEKFD6lHMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/sletning.vue")
  },
  {
    name: "konto-spaering",
    path: "/konto/spaering",
    meta: spaeringBH96dTlc3JMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/konto/spaering.vue")
  },
  {
    name: "kundeservice",
    path: "/kundeservice",
    meta: kundeserviceHXTIH5gzZeMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/kundeservice.vue")
  },
  {
    name: "mobil-app",
    path: "/mobil-app",
    meta: mobil_45app8b8RA76rmpMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/mobil-app.vue")
  },
  {
    name: "om-os",
    path: "/om-os",
    meta: om_45osCPpmh4sBlNMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/om-os.vue")
  },
  {
    name: "playgame-login",
    path: "/playgame/login",
    meta: loginfXFJT5toT3Meta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/playgame/login.vue")
  },
  {
    name: "presse",
    path: "/presse",
    meta: presseqoiIaSA5W3Meta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/presse.vue")
  },
  {
    name: "privatlivspolitik",
    path: "/privatlivspolitik",
    meta: privatlivspolitikURQXJT0F8oMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/privatlivspolitik.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupaDtssRkNU3Meta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/signup.vue")
  },
  {
    name: "spilleautomater-gemte-spil",
    path: "/spilleautomater/gemte-spil",
    meta: gemte_45spilAw7JjKiuhhMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue")
  },
  {
    name: "spilleautomater",
    path: "/spilleautomater",
    meta: indexrBAPQz3AltMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/spilleautomater/index.vue")
  },
  {
    name: "spiloversigt",
    path: "/spiloversigt",
    meta: spiloversigtJIDg5xn1onMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/spiloversigt.vue")
  },
  {
    name: "spilside-seoName",
    path: "/spilside/:seoName()",
    meta: _91seoName_93wd83QFcm9gMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/spilside/[seoName].vue")
  },
  {
    name: "tv",
    path: "/tv",
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/tv.vue")
  },
  {
    name: "vindere",
    path: "/vindere",
    meta: vinderej5i5HOV9ZAMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_oszGSf/0/spilnu/frontend/spilnu/pages/vindere.vue")
  }
]